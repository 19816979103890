<template>
 
  <!-- <div style="background-color:	#202020;"> -->
    
    <MovieList/>
  <!-- </div> -->
  
  
</template>

<script>

//import HelloWorld from './components/HelloWorld.vue'
import MovieList from './components/MovieList.vue'
import $ from 'jquery'
export default {
  name: 'App',
  components:{
    MovieList,
  },
  data(){
    return{
      title: 'Youtube Movie Ranker'
  
    }
  },
  beforeMount(){
    this.getIP();
  },
  methods:{
    getIP(){
      let apiKey = 'ed90702f6a17456192cb6cd4e9da306d';
      $.getJSON('https://ipgeolocation.abstractapi.com/v1/?api_key=' + apiKey, function(data) {
        console.log(JSON.stringify(data, null, 2));
        var ip_address = data.ip_address;
        var region = data.region;
        var region_iso_code = data.region_iso_code;
        var postal_code = data.postal_code;
        var country_code = data.country_code;
        var longitude = data.longitude;
        var latitude = data.latitude;
        var city = data.city;
        var is_vpn = data.security.is_vpn;
        var flag_pic = data.flag.png;
        var isp_name = data.connection.isp_name;
        //data = JSON.parse(data);
       
        console.log(ip_address, region, region_iso_code, postal_code, country_code, longitude, latitude, city, is_vpn, flag_pic, isp_name);


        fetch('https://api.nathanhung.com/visit', {
          method: 'POST',
          headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
          },
          body: JSON.stringify({ 
            "ip_address": ip_address, 
            "region":region, 
            "region_iso_code":region_iso_code, 
            "postal_code":postal_code, 
            "country_code":country_code,  
            "longitude":longitude , 
            "latitude": latitude, 
            "city":city, 
            "is_vpn":is_vpn, 
            "flag_pic":flag_pic, 
            "isp_name":isp_name})
          })
          .then(response => response.json())
          //.then(response => console.log(JSON.stringify(response)))


        return JSON.stringify(data, null, 2);

        
      });
    }
  }
}
</script>

<style>
@import './assets/css/bootstrap-theme.css';
@import './assets/css/bootstrap-theme.min.css';
@import './assets/css/bootstrap.css';
@import './assets/css/bootstrap.min.css';

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 0px;
  background-image: linear-gradient( rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.9) ), url('assets/Background.jpg');
  background-color:	#202020; 
}

body{
  background-image: url('assets/Background.jpg');
  } 
</style>
