<template>
<div class="d-flex flex-row bg-warning align-items-center">
    <span v-if="notificationsShow == true && isMobile() == true" class="h4">In-site video playback may not be available on your mobile device. For best experience, use a desktop browser.</span>
    <button v-if="notificationsShow == true && isMobile() == true" class="btn-lg btn btn-warning text-dark h2" @click="closeNotifications" aria-label="Close">
        
        
            x
        
        <!-- <span class="p-4" aria-hidden="true">
            x
        </span> -->
    </button>
</div>
<!--Title, About and Contact-->

    <div class="d-flex flex-row justify-content-between m-3">
        <div style="width: 100px;"></div>
        <h1 class="text-secondary">Youtube Movie Ranker</h1>
        <button type="button" class="btn btn-outline-light h2" style="width: 100px;"  data-toggle="modal" data-target="#aboutModal">About</button>
    </div>
    <!--About Modal-->
    <div class="modal modalNoRightPadding fade" id="aboutModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog" style="min-width: 60vw;" role="document">
            <div class="modal-content bg-dark text-light">
            <div class="modal-header">
                <h5 class="modal-title h3" id="exampleModalLabel">About Youtube Movie Ranker</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span class="text-light" aria-hidden="true">
                        &times;
                    </span>
                </button>
            </div>
            <div class="modal-body">

                <p class="text-center font-weight-bold h3"><u>Why was this website made?</u></p>
                    <ul class="list-group text-left h4 px-5">
                        <li class="bg-dark text-light m-3">
                            The first problem is the difficulty of searching for IMDb data every time I found an interesting movie. 
                            This website integrates IMDb data seamlessly with each movie.
                        </li>
                        <li class="bg-dark text-light m-3">
                            The second problem I encountered, is lack of the ability to order by metrics like YouTube views, IMDb score, release year, and popularity.
                            This website solves that issue by providing easy-to-use, order by operations, to cater to different preferences.
                        </li>
                        <li class="bg-dark text-light m-3">
                            This website provides all in 1 solution for searching and watching free youtube movies without needing to leave the site unless the movie is restricted by YouTube.
                        </li>
                     
                    </ul>
                    <br> 
                    <p class="text-center font-weight-bold h3"><u>Technologies used</u></p>

                    <ul class="text-left h4 px-5">
                        <li class="bg-dark text-light m-3">
                            This website is built using Flask, Vuejs, and MongoDB, thereby making it a completely headless solution
                        </li>
                        <li class="bg-dark text-light m-3">
                            Data Aquisition and Storage
                            <ul class="text-left h4">
                                <li class="bg-dark text-light m-3">Youtube API</li>
                                <li class="bg-dark text-light m-3">IMDB Datasets</li>
                                <li class="bg-dark text-light m-3">Python for ETL</li>
                                <li class="bg-dark text-light m-3">MongoDB</li>
                            </ul>
                        </li>
                        <li class="bg-dark text-light m-3">
                            Back-End Server
                            <ul class="text-left h4">
                                <li class="bg-dark text-light m-3">Built using Flask, a python framework</li>
                                <li class="bg-dark text-light m-3">Flask hosts and processes RESTful API from Vue app.</li>
                                <li class="bg-dark text-light m-3">Performs CRUD operations to MongoDB</li>
                            </ul>
                        </li>
                        <li class="bg-dark text-light m-3">
                            Front-End
                            <ul class="text-left h4">
                                <li class="bg-dark text-light m-3">Vue 3, a javascript framework</li>
                                <li class="bg-dark text-light m-3">Abstract API</li>
                                <li class="bg-dark text-light m-3">Bootstrap</li>
                                
                            </ul>
                        </li>
                     
                    </ul>
                    <p class="text-left h4">

                    </p>

                
                <p class="text-center font-weight-bold h3"><u>Credits</u></p>
                <p class="bg-dark text-light h4">
                    Thank you to 
                    <a href="https://www.reddit.com/r/PlexPosters/comments/ck1rmx/poster_near_4k_collage_containing_330_movie/" target="_isblank" class="text-left h5">u/DougS2K</a>
                    for providing the background image for this website.
                </p>
                <br>
                <p class="text-center font-weight-bold h3"><u>Special Request</u></p>
                <p class="bg-dark text-left h4">
                    &emsp;For those in the tech field. I am a soon to graduate senior seeking job opportunities in the data engineering 
                    and software engineering field. If you can provide any referrals, leads, or even interviews for entry level roles. 
                    Please click the Email button below to shoot me a quick email. 
                </p>
                <button v-if="sentEmail == false" @click="contactButtonClick()" type="button" class="btn btn-primary h2" style="width: 100px;">Email Creator</button>
                <!--form to contact-->
                <form v-if="showContactForm == true && sentEmail == false">
                    <label for="exampleFormControlInput2">Full Name</label>
                    <div class="d-flex form-group justify-content-center">
                        <input v-model="name" class="form-control bg-dark text-light" style="width:400px;" id="exampleFormControlInput2" placeholder="Add your full name">
                    </div>
                    <br>
                    <label for="exampleFormControlInput1">Email address</label>
                    <div class="d-flex form-group justify-content-center">
                        <input v-model="email" type="email" class="form-control bg-dark text-light" style="width:400px;" id="exampleFormControlInput1" placeholder="name@example.com">
                    </div>
                    <br>
                    <label for="exampleFormControlTextarea1">Message</label>
                    <div class="d-flex form-group justify-content-center">
                        <textarea v-model="message" class="form-control bg-dark text-light" style="width:400px;" id="exampleFormControlTextarea1" rows="3" placeholder="Type your message here"></textarea>
                    </div>
                </form>
                <button v-if="sentEmail == false && showContactForm == true" @click="emailSubmitted()" type="button" class="btn btn-primary h2" style="width: 100px;">Send Email</button>
                <p v-if="sentEmail == true" class="text-center font-weight-bold h3 text-success">Thank you for your email, I will respond as soon as possible</p>

                <!--End form-->
                
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
               <!--  <button type="button" class="btn btn-primary">Save changes</button> -->
            </div>
            </div>
        </div>
    </div>

   
    
    <!--Sorting Form-->
    <form>
        <div class="d-flex justify-content-center">
            <div class="d-flex flex-row flex-wrap justify-content-center text-white w-50">
                <div class="col-sm">
                    
                    <label class="mr-sm-2 h3" for="inlineFormCustomSelect">Order By Field</label>
                    <select class="custom-select mr-sm-2 bg-dark text-white h3 decorated" style="height:40px;" id="inlineFormCustomSelect" 
                        v-model="orderByValue"
                        @change="orderBy(orderByValue)">

                        <option class="bg-dark text-white h5" value="averageRating" selected>IMDB Rating</option>
                        <option class="bg-dark text-white h5" value="numVotes">IMDB Votes</option>
                        <option class="bg-dark text-white h5" value="likeCount">Youtube Likes</option>
                        <option class="bg-dark text-white h5" value="releaseYear">Release Year</option>
                    </select>
                    
                </div>
                <div class="col-sm">
                    
                    <label class="mr-sm-2 h3" for="inlineFormCustomSelect">Sort</label>
                    <select class="custom-select mr-sm-2 bg-dark text-white h3" style="height:40px;" id="inlineFormCustomSelect" 
                        v-model="orderByOrder"
                        @change="orderBy(orderByValue)">
                        <option class="bg-dark text-white h5" value="desc" selected>Highest to Lowest</option>
                        <option class="bg-dark text-white h5" value="asc">Lowest to Highest</option>
                    
                    </select>
                    
                </div>

              
            
                
            </div>
        </div>
       
    </form>
    
    
    

    <div class="d-flex flex-wrap justify-content-center">
        <!--Ad-->
        <div class = "p-3">
            <div 
            @mouseenter = "adHoverFunction()"
            @mouseleave = "adHoverFunction()" 
            :class="adIfHover ?'border-white': 'border-dark'"
            class="card text-white bg-dark crop border border-3 rounded" style="width: 30rem;" >

                <div id="adsgoeshere" class="p-3 rounded" style="background: #1d1f29; padding-top:60px; text-align: center;" 
                v-html="adsenseContent"></div>
                <div class="card-body">
                    

                    <div class="container">
                        
                        <!--Genres-->

                        <div class="row">
                            <div class="d-flex flex-row align-items-center h4">
                                <span 
                                    class="badge rounded-pill bg-secondary m-1"
                                    style="--bs-border-opacity: .5;">
                                        Ad
                                </span>

                            </div>
                        </div>
                        
                        <!-- <div class="p-3">
                        <button type="button" @click="currentMovie($event, movie)" class="btn btn-outline-primary stretched-link btn-block" data-toggle="modal" v-bind:data-target="'#v-bindid'+movie._id.$oid" data-backdrop="static" data-keyboard="false">Free to Watch</button>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>

        <!--Cards-->

        <div 
            @mouseenter = "hoverFunction(movie)"
            @mouseleave = "hoverFunction(movie)"  
            
            v-for="movie in orderedMovies" :key="movie.videoID" class = "p-3">
            
            <div>
                <div 
                :class="movie.ifHover ?'border-white': 'border-dark'"
                class="card text-white bg-dark crop border border-3 rounded" style="width: 30rem;" >

                    <img class="centered-and-cropped center card-img-top rounded movieCardMouseClickable" width="200" height="400"  v-bind:src="movie.thumbnail" alt="Card image cap">
                    <div class="card-body">
                        <p v-if="movie.hasIMDB == true" class="card-text h3">{{ movie.title }} ({{ movie.releaseYear }})</p>
                        <p v-else class="card-text h3">{{ movie.title }}</p>

                        <div class="container">
                            <!--IMDB and Likes-->
                            <div class="row">
                                
                                <div v-if="movie.hasIMDB == true" class="col-sm">
                                    <div class = "row justify-content-center h5">
                                        IMDB Rating
                                    </div>
                                    <div v-bind:class="ratingGroup(movie.averageRating)" class = "row justify-content-center h4">
                                        {{ movie.averageRating }}
                                    </div>
                                </div>


                                <div v-if="movie.hasIMDB == false" class="col-sm">
                                    
                                    <div class = "row justify-content-center h5 ">
                                        IMDB Rating
                                    </div>
                                    <div class = "row justify-content-center h4 text-secondary">
                                        Unavailable
                                    </div>
                                </div>

                                <div class="col-sm">
                                    <div class = "row justify-content-center h5 ">
                                        YouTube Likes
                                    </div>
                                    <div class = "row justify-content-center h4 text-primary">
                                        {{ nFormatter(movie.likeCount)}}
                                    </div>
                               
                                </div>
                            </div>

                            <!--Genres-->

                            <div class="row">
                                <div class="d-flex flex-row align-items-center h4">
                                    <span 
                                        v-for="genre in movie.genres" :key="genre" 
                                        v-bind:class="genreGroup(genre)"
                                        class="badge rounded-pill bg-secondary m-1"
                                        style="--bs-border-opacity: .5;">
                                            {{genre}}
                                    </span>

                                </div>
                            </div>
                            
                            <div class="p-3">
                            <button type="button" @click="currentMovie($event, movie)" class="btn btn-outline-primary stretched-link btn-block" data-toggle="modal" v-bind:data-target="'#v-bindid'+movie._id.$oid" data-backdrop="static" data-keyboard="false">Free to Watch</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    
        </div>

    
    </div>

    <!--Desktop Modal -->
    <div v-if="this.isMovieModalOpen && !isMobile()" class="flex-container justify-content-center close-modal-class"> 
        <div class="modal modalNoRightPadding fade flex-container justify-content-center" @hide="closeModal" style="margin:auto;" v-bind:id="'v-bindid'+modalMovieDisplay._id.$oid"  tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" >
            <div class="modal-dialog flex-container justify-content-center" style="max-width: 85vw; margin:auto;" role="document" >
                <div class="modal-content bg-dark text-light" >
                    <div class="modal-header border-secondary">
                        <h3 class="modal-title" id="exampleModalLabel">{{ modalMovieDisplay.title }}</h3>
                        
                        <button type="button" class="close" @click="closeModal" data-dismiss="modal" aria-label="Close">
                            <span class="text-light" aria-hidden="true">
                                &times;
                            </span>
                        </button>
                    </div>
                    <div class="modal-body">
                        
                        <iframe width="100%" style="height : 80vh;" v-bind:src="'https://www.youtube-nocookie.com/embed/' + modalMovieDisplay.videoID" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                        <div class="container-fluid">
                            <!--Genres-->
                            <div class="row h4">
                                <div class="col">
                                    <div class="d-flex flex-row">
                                        <span 
                                            v-for="genre in modalMovieDisplay.genres" :key="genre" 
                                            v-bind:class="genreGroup(genre)"
                                            class="badge rounded-pill bg-secondary m-1"
                                            style="--bs-border-opacity: .5;">
                                                {{genre}}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <!--First row right under Genres-->
                            <div class="row h4 align-items-center">
                                <!--this column shows video statistics-->
                                <div class="col">

                                    <div class="d-flex flex-row align-items-center">
                                        <div class="d-flex justify-content-center p-3">
                                            <div class=" p-1">
                                                Likes                                        
                                            </div>
                                            <div class=" p-1">
                                                {{ nFormatter(modalMovieDisplay.likeCount)}}
                                            </div>
                                        </div>

                                        <div class="d-flex justify-content-center p-3">
                                            <div class=" p-1">
                                                Comments                                        
                                            </div>
                                            <div class=" p-1">
                                                {{ nFormatter(modalMovieDisplay.commentCount)}}
                                            </div>
                                        </div>

                                        <div class="d-flex justify-content-center p-3">
                                            <div class=" p-1">
                                                Released Year                                       
                                            </div>
                                            <div class=" p-1">
                                                {{ modalMovieDisplay.releaseYear}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!--this column displays the Video not Available/Watch on Youtube-->
                                <div class="col-md-auto">
                                    <div class="d-flex flex-row align-items-center">
                                        <div class=" p-3">
                                        Video unavailable?
                                        </div>
                                        <div class=" p-3 btn-lg">
                                            <a v-bind:href="'https://www.youtube.com/watch?v=' + modalMovieDisplay.videoID" class="btn btn-secondary btn-lg" role="button" target="_blank">Watch on YouTube</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row h4">
                                <!--this column shows video statistics-->
                                <div class="col">
                                    <div class="d-flex flex-row align-items-center">
                                        <div v-if="modalMovieDisplay.hasIMDB == true" class="d-flex justify-content-center p-3">
                                            <div class=" p-1">
                                                IMDb                                        
                                            </div>
                                            <div v-bind:class="ratingGroup(modalMovieDisplay.averageRating)" class=" p-1">
                                                {{ modalMovieDisplay.averageRating }}  
                                            </div>
                                        </div>
                                        <div v-if="modalMovieDisplay.hasIMDB == true" class="d-flex justify-content-center p-3">
                                            <div class=" p-1">
                                                IMDb Votes                                        
                                            </div>
                                            <div class=" p-1">
                                                {{ nFormatter(modalMovieDisplay.numVotes) }}  
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!--this column displays the Video not Available/Watch on Youtube-->
                                <div class="col">
                                    
                                </div>
                            </div>
                        </div>
                        <p class="text-left h4">{{ modalMovieDisplay.description}}</p>
                    </div>
                    <div class="modal-footer border-secondary">
                        <!-- <button type="button" class="btn btn-secondary" data-dismiss="modal" @click="closeModal">Close</button>
                        <button type="button" class="btn btn-primary">Save changes</button> -->
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Mobile Modal -->
    <div id="MyModal" v-if="this.isMovieModalOpen && isMobile()" class="flex-container justify-content-center"> 
        <div class="modal modalNoRightPadding fade flex-container justify-content-center" style="margin:auto;" v-bind:id="'v-bindid'+modalMovieDisplay._id.$oid"  tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" >
            <div class="modal-dialog modal-dialog-centered flex-container justify-content-center" style="max-width: 100vw; margin:auto;" role="document" >
                <div class="modal-content bg-dark text-light" >
                    <div class="modal-header border-secondary">
                        <h3 class="modal-title" id="exampleModalLabel">{{ modalMovieDisplay.title }}</h3>
                        <button type="button" @click = "closeModal" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true" class="text-light">&times;</span>
                        </button>
                    </div>
                    
                    <div class="modal-body">
                        
                        <iframe id="yt_player_iframe" class="yt_player_iframe" v-if="isMovieModalOpen" width="100%" style="height:25vh;" v-bind:src="'https://www.youtube-nocookie.com/embed/' + modalMovieDisplay.videoID + '&enablejsapi=1'" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                        <!--<a v-bind:href="'https://www.youtube.com/watch?v=' + modalMovieDisplay.videoID" class="btn btn-info" role="button">Redirect to YouTube App</a>--> 
                        <div class="container-fluid">
                            <!--First row right under video-->
                            <div class="row h4">
                                <!--this column shows video statistics-->
                                <div class="col">

                                    <div class="d-flex flex-row justify-content-center align-items-center">
                                        <div class="d-flex justify-content-center p-3">
                                            <div class=" p-1">
                                                Likes                                        
                                            </div>
                                            <div class=" p-1">
                                                {{ nFormatter(modalMovieDisplay.likeCount)}}
                                            </div>
                                        </div>
                                        
                                        
                                        <div v-if="modalMovieDisplay.hasIMDB == true" class="d-flex justify-content-center p-3">
                                            <div class=" p-1">
                                                IMDB                                        
                                            </div>
                                            <div v-bind:class="ratingGroup(modalMovieDisplay.averageRating)" class=" p-1">
                                                {{ modalMovieDisplay.averageRating }}  
                                            </div>
                                        </div>
                                        <div v-if="modalMovieDisplay.hasIMDB == true" class="d-flex justify-content-center p-3">
                                            <div class=" p-1">
                                                IMDB Votes                                        
                                            </div>
                                            <div class=" p-1">
                                                {{ nFormatter(modalMovieDisplay.numVotes) }}  
                                            </div>
                                        </div>
                                        
                                    </div>

                                </div>
                                <!--this column displays the Video not Available/Watch on Youtube-->
                                <div class="d-flex flex-col justify-content-center align-items-center">
                                    <div class="d-flex flex-row justify-content-center align-items-center">
                                        <div class=" p-3">
                                        Video unavailable?
                                        </div>
                                        <div class=" p-3 btn-lg">
                                            <a v-bind:href="'https://www.youtube.com/watch?v=' + modalMovieDisplay.videoID" class="btn btn-secondary btn-lg" role="button" target="_blank">Watch on YouTube</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <p class="text-left h4">{{ modalMovieDisplay.description}}</p>

                        
                        

                    </div>
                
                    <div class="modal-footer border-secondary">
                        <!-- <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                        <button type="button" class="btn btn-primary">Save changes</button> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
    
    <!-- <Modal v-if="this.ifMovieModalOpen" :movie = "modalMovieDisplay"/> -->
</template>

<script>
//import Modal from '../components/MovieModal.vue'
import axios from 'axios'
//import LiteYouTubeEmbed from 'vue-lite-youtube-embed'
import 'vue-lite-youtube-embed/style.css'
import { defineComponent } from 'vue'
import $ from 'jquery'
//import YouTube from 'vue3-youtube'



    export default defineComponent({
        name:'MovieList',
        data(){
            return{
                movies: [],
                isHover: false,
                isMovieModalOpen: false,
                modalMovieDisplay: [],
                modalStatus : $('#modal').is(':visible'),
                orderByValue: 'averageRating',
                orderByOrder: 'desc',
                orderedMovies: [],
                showContactForm: false,
                sentEmail: false,
                name:"",
                email:"",
                message:"",
                notificationsShow: true,
                adsenseContent: '',
                adIfHover : false
            }
        },
        components:{
  
        },
        methods: {
            getMovies(){
                axios.get('https://api.nathanhung.com/moviedata',
                {
                    headers:{
                        'Ip-Header': 'testip'
                            }
                })            
                    .then((response) => { 

                        var movies = JSON.parse(response.data)

                        Object.keys(movies).forEach(function(key) {
                        
                            movies[key]["ifHover"] = false
                      
                            if(Object.prototype.hasOwnProperty.call(movies[key], "averageRating")) {
                                movies[key]["hasIMDB"] = true
                            }
                            else{
                                movies[key]["hasIMDB"] = false
                            }
                            
                         
                    
                           
                        

                        
                        });
                        

                        function filterIMDB(movie) {
                            return movie.hasIMDB == true;
                        }
                        var imdbMovieFiltered = movies.filter(filterIMDB);

                        this.orderedMovies = imdbMovieFiltered.slice().sort(function(a, b){
                            return (a.averageRating < b.averageRating) ? 1 : -1;
                        });

                        this.movies = movies;
                        
                    })
                    .catch((error) => {
                        console.log(error)
                    })
            },
            hoverFunction(movie)
            {
                movie.ifHover = !movie.ifHover
            },
            adHoverFunction()
            {
                let ifHover = !this.adIfHover
                this.adIfHover = ifHover
            },
            onReady(){
                this.$refs.youtube.playVideo()
              
                
            },
            currentMovie(event,movie)
            {
                console.log(event)
                this.modalMovieDisplay = movie
                console.log(this.modalMovieDisplay)
                this.isMovieModalOpen = true
            },
            isMobile() {
                if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                    return true
                } else {
                    return false
                }
            },
            ratingGroup: function(averageRating){
                
                if(averageRating < 5){
                    return 'text-danger'
                }
                else if(averageRating < 7){
                    return 'text-warning'
                }
                else{
                    return 'text-success'
                }
                
            },
            genreGroup: function(genre){
                
                switch(genre){
                    case "Romance":
                        return 'border pink-pill';
                    case "Comedy":
                        return 'border border-warning';
                    case "Adventure":
                        return 'border border-success';
                    case "Crime":
                        return 'border red-pill';
                    case "Action":
                        return 'border red-pill';
                    case "Drama":
                        return 'border purple-pill';
                    case "Sport":
                        return 'border orange-pill';
                    case "War":
                        return 'border border red-pill';
                    case "Thriller":
                        return 'border border red-pill';
                    case "Family":
                        return 'border border brown-pill';
                    case "Music":
                    return 'border border violet-pill';
                    default:
                        return 'border border-primary'

                }

            },
            nFormatter(num, digits = 1) {
                const lookup = [
                    { value: 1, symbol: "" },
                    { value: 1e3, symbol: "k" },
                    { value: 1e6, symbol: "M" },
                    { value: 1e9, symbol: "G" },
                    { value: 1e12, symbol: "T" },
                    { value: 1e15, symbol: "P" },
                    { value: 1e18, symbol: "E" }
                ];
                const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
                var item = lookup.slice().reverse().find(function(item) {
                    return num >= item.value;
                });
                return item ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol : "0";
            },
            closeModal(){
                this.isMovieModalOpen=false
                $(".close-modal-class iframe").attr("src", $(".close-modal-class iframe").attr("src"));
            },
            sortArrays() {
                function filterIMDB(movie) {
                    return movie.hasIMDB == true;
                }
                var imdbMovieFiltered = this.movies.filter(filterIMDB);

                return imdbMovieFiltered.slice().sort(function(a, b){
                    return (a.averageRating < b.averageRating) ? 1 : -1;
                });
            },
            orderBy(orderByValue){
                const needIMDBFiltering = ["averageRating", "numVotes", "releaseYear"]
                var preProcessedMovies = []
                var copyMovies = this.movies
                //filtering null values
                function filterIMDB(movie) {
                    return movie.hasIMDB == true;
                }

                //determines whether it needs filtering or not
                if (needIMDBFiltering.includes(orderByValue))
                {
                    preProcessedMovies = copyMovies.filter(filterIMDB);
                }
                else{
                    preProcessedMovies = copyMovies;
                    
                }
                
                //ordering by integer

                //ordering
                if(this.orderByOrder == "desc"){
                    this.orderedMovies = preProcessedMovies.slice().sort(function(a, b){
                        return (parseFloat(a[orderByValue]) < parseFloat(b[orderByValue])) ? 1 : -1;
                    });

                    
                }
                else{
                    this.orderedMovies = preProcessedMovies.slice().sort(function(a, b){
                        return (parseFloat(a[orderByValue]) > parseFloat(b[orderByValue])) ? 1 : -1;
                    });
                }
                console.log(this.orderedMovies)

                
            },
            contactButtonClick(){
                this.showContactForm = !this.showContactForm
            },
            emailSubmitted(){
                this.sentEmail = true
                fetch('https://api.nathanhung.com/email', {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({ 
                        "name": this.name, 
                        "email": this.email,
                        "message": this.message
                    })
                        
                    })
                    .then(response => response.json())
                    //.then(response => console.log(JSON.stringify(response)))
                
            },
            closeNotifications(){
                this.notificationsShow = false
            }
        },
        computed:{
        },
        beforeMount(){
            this.getMovies()
        },
        mounted: function(){
            this.adsenseContent = document.getElementById('divadsensedisplaynone').innerHTML

        }
    })
    //Stop youtube video from playing when modal is closed
    $(".close-modal-class").on('hidden.bs.modal', function (e) {
        $(".close-modal-class iframe").attr("src", $(".close-modal-class iframe").attr("src"));
        console.log(e);
    });
   

</script>

<style scoped>

.centered-and-cropped { object-fit: cover }
.border-3 {
    border-width:5px !important;
    border-radius: 10px !important;
}
.movieCardMouseClickable{
    cursor: pointer;
}
.modalNoRightPadding{ padding-right: 0 !important }

.pink-pill {border-color:pink !important;}
.red-pill {border-color:darkred !important;}
.purple-pill {border-color:purple !important;}
.orange-pill {border-color:orange !important;}
.brown-pill {border-color:rgb(72, 57, 31) !important;}

.violet-pill {border-color:rgb(255, 0, 255) !important;}

</style>