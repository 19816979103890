//import {Vue, createApp} from 'vue'
import * as Vue from 'vue'
import App from './App.vue'
//import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import './assets/css/bootstrap.min2.css';

let app = Vue.createApp(App)

app.mount('#app')



//createApp(App).mount('#app')


